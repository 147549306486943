import React, {useState} from 'react'


export default function YoutubeVideo({videoid, desc, w, h}) {
    const [playing, setPlaying] = useState(false)
    return (
        <div  style={{backgroundColor:"hsla(0, 0%, 0%, 0.1)"}}>
            {playing ?
                <iframe width={w-2} height={w*0.5625} src={"https://www.youtube.com/embed/"+videoid+"?&autoplay=1&modestbranding=1&rel=0"} 
                frameborder="0" allow="allowfullscreen; accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                allowfullscreen style={{marginBottom:"0px"}}></iframe>
            :
            <img width={w-2+"px"} onClick={()=>{setPlaying(true)}} src={"https://img.youtube.com/vi_webp/"+videoid+"/mqdefault.webp"} style={{marginBottom:"3px "}}  alt=""/>}
            <div style={{padding:"5px"}}>{desc}</div>
        </div>
    )
}
