import React, { useRef, useState, useEffect } from "react"
import YoutubeVideo from "../components/YoutubeVideo"
import Layout from "../components/layout"
import wfhThumb from "../images/workFromHome_thumb.jpg"
import { Link } from "gatsby"


export default function Tips() {
  const [sectionWidth, setSectionWidth] = useState(0)
  const location = {
    href:"https://covidtracker.wjatt.com/tips"
  }
  const sectionRef = useRef(null)
  const videos = [
    {
      id: "TKx-F4AKteE",
      desc: "How to Safely Grocery Shop During Coronavirus",
    },
    {
      id: "seA1wbXUQTs",
      desc: "Washing hands",
    },
    {
      id: "qilLP_UnaHg",
      desc: "How to wear masks",
    },
    {
      id: "8c_UJwLq8PI",
      desc: "Seven steps to prevent the spread of the virus",
    },
  ]

  https://www.who.int/images/default-source/health-topics/coronavirus/myth-busters/mythbuster-2.tmb-1920v.png?sfvrsn=635d24e5_3
  useEffect(() => {

    function debounce(func, wait, immediate) {
        var timeout;
        return function() {
            var context = this, args = arguments;
            var later = function() {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    };
    const updateWidth = debounce(()=>{
        
            setSectionWidth(sectionRef.current.clientWidth)
      
    },500)
    //setSectionWidth(sectionRef.current.clientWidth)
    updateWidth()
    window.addEventListener("resize", updateWidth)
    return () => {
      window.removeEventListener("resize", updateWidth)
    }
  }, [])
  return (
    <Layout location={location}>
      <h3>Tips</h3>
      <h5>Articles</h5>
      <section
        style={{
          display: "grid",
          gridTemplateColumns: sectionWidth && sectionWidth < 600? "1fr": "1fr 1fr",
          columnGap: "4px",
          marginBottom: "30px",
        }}
      >
        <Link
          to="/workingeffectively"
          style={{
            textDecoration: "none",
            color: "hsla(0, 0%, 0%, 0.8)",
            backgroundColor: "hsla(0, 0%, 0%, 0.1)",
          }}
        >
          <img src={wfhThumb} alt="" style={{ marginBottom: "3px " }} />
          <div style={{ padding: "5px" }}>Working Remotely Effectively </div>
        </Link>
        <Link
          to="/myths"
          style={{
            textDecoration: "none",
            color: "hsla(0, 0%, 0%, 0.8)",
            backgroundColor: "hsla(0, 0%, 0%, 0.1)",
          }}
        >
          <img src="https://www.who.int/images/default-source/health-topics/coronavirus/myth-busters/web-mythbusters/mb-sun-exposure.tmb-1200v.jpg?sfvrsn=658ce588_1
" alt="" style={{ marginBottom: "3px " }} />
          <div style={{ padding: "5px" }}> 18 common myths about covid-19</div>
        </Link>

      </section>
      <h5>Videos</h5>
      <section
        ref={sectionRef}
        style={{
          display: "grid",
          gridTemplateColumns: sectionWidth && sectionWidth < 600? "1fr": "1fr 1fr",
          columnGap: "4px",
          rowGap: "4px",
          marginBottom: "30px",
        }}
      >
        {videos.map(video => {
          return (
            <YoutubeVideo
              videoid={video.id}
              key={video.id}
              desc={video.desc}
              w={sectionRef.current ?( sectionWidth && sectionWidth < 600? sectionWidth : sectionWidth / 2 ): 0}
            ></YoutubeVideo>
          )
        })}
      </section>
      <h5>Other useful links</h5>
      <section>
        <div>
          <a href="http://www.health.gov.tt/sitepages/default.aspx?id=292"
          rel="noopener noreferrer" >Ministry of Health</a>
        </div>
      </section>
    </Layout>
  )
}
